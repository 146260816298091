import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/gregoirethibaud/Desktop/Brusketa/Developpement/brusketa/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`Bienvenue sur la documentation du projet Brusketa ! Vous trouverez sur ce site toutes les informations concernant la façon dont est organisée notre façon de coder. L'objectif est d'homogénéiser nos pratiques de code pour fournir une application robuste. Nous essayons d'adopter les nouvelles fonctionnalités React afin de nous tenir à la page. Si vous avez des questions sur les outils React que nous utilisons, n'hésitez pas à consulter la documentation React (en français), qui est super claire : `}<a parentName="p" {...{
        "href": "https://fr.reactjs.org/docs/"
      }}>{`https://fr.reactjs.org/docs/`}</a>{`.`}</p>
    <h2 {...{
      "id": "organisation-des-fichiers"
    }}>{`Organisation des fichiers`}</h2>
    <p>{`Notre arborescence est découpée en plusieurs fichiers :`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/screens`}</inlineCode>{` : contient toutes les pages de l'application, composées de plusieurs composants ;`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/components`}</inlineCode>{` : contient tous les composents granulaires de l'application. Certains de ces composants sont réutilisés les uns dans les autres. Ces composants peuvent accéder au contexte de l'application. La documentation des composants est disponible dans le menu "Components" (cf. barre latérale gauche) ;`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/assets`}</inlineCode>{` : contient les ressources d'interface : icônes, fichier de traduction, etc. Toutes les ressources doivent y être placées ;`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/config`}</inlineCode>{` : contient les fichiers de configuration ; par exemple les fichiers de connexion à Firebase ;`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/contexts`}</inlineCode>{` : contient tous les contextes de l'application (`}<a parentName="li" {...{
          "href": "#Contextes"
        }}>{`plus d'informations`}</a>{`).`}</li>
    </ul>
    <p>{`Le fichier racine de l'application est `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`. Tous les contextes y sont fournis.`}</p>
    <h2 {...{
      "id": "contextes"
    }}>{`Contextes`}</h2>
    <p>{`Nous utilisons donc les contextes pour gérer les données de l'application. À chaque objet métier de la base de données correspond un contexte (Box pour boîte à idées, Category pour les catégories de boîtes à idées, etc.). `}</p>
    <p>{`L'objectif est de conserver les contextes tout au long de la session d'utilisation de l'application. Par exemple, le contexte utilisateur `}<inlineCode parentName="p">{`UserContext`}</inlineCode>{` permet de partager les données des utilisateurs entre tous les composants de l'application, sans avoir à les recharger depuis Firebase à chaque chargement d'un nouveau composant ou d'un nouvel écran. Ceci permet d'optimiser le nombre d'appels à Firebase`}</p>
    <p>{`Tous les contextes sont fournis à la racine de l'application (dans le fichier `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`).`}</p>
    <h2 {...{
      "id": "hooks--composants-fonctions"
    }}>{`Hooks & composants fonctions`}</h2>
    <p>{`Tous nos composants sont des arrow-functions.`}</p>
    <h2 {...{
      "id": "async-vs-sync"
    }}>{`Async vs. sync`}</h2>
    <p>{`Les appels à la base de données Firebase sont quasiment tous asynchrones (pas de temps réel). Nous `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      